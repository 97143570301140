import React from "react";
import fondoBase from "../assets/wassermatic.jpg";
import iconYoutube from "../assets/youtube-logo.png";
import btL from "../assets/btL.png";
import btR from "../assets/btR.png";

import YouTube from "react-youtube";

import "../App.css";
//import Button from "@material-ui/core/Button";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class CompYoutubeGallery extends React.Component {
  constructor(props) {
    super(props);
    //"http://127.0.0.1/webwassermaticvendinginnovation/index.php/apiViews/getListaBanners",
    this.state = {
      vistaActual: 0, //0 no muestra  , 1 youtube detalles
      vistaCarousel: 0,
      urlLista: "https://wassermatic.com.mx/index.php/apiViews/getListaTube",
      numeroTotal: "0",
      numeroActual: "0",
      tipoArchivoActual: "0",
      urlArchivoActual: fondoBase,
      idYoutubeActual: "",
      dataLista: [],
      item01: {
        T_TITULO: "Item 001",
        T_URL_ARCHIVO: fondoBase,
        E_ID_YOUTUBE: "",
      },
      item02: {
        T_TITULO: "Item 002",
        T_URL_ARCHIVO: fondoBase,
        E_ID_YOUTUBE: "",
      },
      item03: {
        T_TITULO: "Item 003",
        T_URL_ARCHIVO: fondoBase,
        E_ID_YOUTUBE: "",
      },
      item04: {
        T_TITULO: "Item 004",
        T_URL_ARCHIVO: fondoBase,
        E_ID_YOUTUBE: "",
      },
      item05: {
        T_TITULO: "Item 005",
        T_URL_ARCHIVO: fondoBase,
        E_ID_YOUTUBE: "",
      },
      item06: {
        T_TITULO: "Item 006",
        T_URL_ARCHIVO: fondoBase,
        E_ID_YOUTUBE: "",
      },
      item07: {
        T_TITULO: "Item 007",
        T_URL_ARCHIVO: fondoBase,
        E_ID_YOUTUBE: "",
      },
      item08: {
        T_TITULO: "Item 008",
        T_URL_ARCHIVO: fondoBase,
        E_ID_YOUTUBE: "",
      },
      item09: {
        T_TITULO: "Item 009",
        T_URL_ARCHIVO: fondoBase,
        E_ID_YOUTUBE: "",
      },
      item10: {
        T_TITULO: "Item 010",
        T_URL_ARCHIVO: fondoBase,
        E_ID_YOUTUBE: "",
      },
      contGlobal: -1,
      timeOutFwd: null,
      timeOutBck: null,
      urlLinkCanal: "https://www.youtube.com/channel/UCPy145L_JjxuvdC5gro6GMg",
      urlRepositorio: "https://wassermatic.com.mx/assets/img/youtube/",
    };
    //bind de los eventois click
    this.fnClickAddProducto = this.fnClickAddProducto.bind(this);
    this.fnCambio = this.fnCambio.bind(this);
    this.fnCambioBack = this.fnCambioBack.bind(this);
    this.fnVerVideo = this.fnVerVideo.bind(this);
    this.fnVerVideo001 = this.fnVerVideo001.bind(this);
    this.fnVerVideo002 = this.fnVerVideo002.bind(this);
    this.fnVerVideo003 = this.fnVerVideo003.bind(this);
    this.fnVerVideo004 = this.fnVerVideo004.bind(this);
    this.fnVerVideo005 = this.fnVerVideo005.bind(this);
    this.fnVerVideo006 = this.fnVerVideo006.bind(this);
    this.fnVerVideo007 = this.fnVerVideo007.bind(this);
    this.fnVerVideo008 = this.fnVerVideo008.bind(this);
    this.fnVerVideo009 = this.fnVerVideo009.bind(this);
    this.fnVerVideo010 = this.fnVerVideo010.bind(this);
    this.fnCerrarVideo = this.fnCerrarVideo.bind(this);
  }

  fnClickAddProducto() {
    // alert("Clicked Button");
    this.setState({ vistaActual: 1 });
  }

  componentDidMount() {
    this.fnLista();

    //  alert("hola");
    // setInterval(() => {
    //   console.log('Interval triggered');
    // }, 1000);
  }

  fnVerVideo() {
    clearTimeout(this.state.timeOutFwd);
    clearTimeout(this.state.timeOutBck);
    this.setState({ vistaActual: 1 });
  }
  fnVerVideo001() {    
    this.setState({ idYoutubeActual: this.state.item01.E_ID_YOUTUBE });
    this.setState({ vistaActual: 1 });
  }
  fnVerVideo002() {    
    this.setState({ idYoutubeActual: this.state.item02.E_ID_YOUTUBE });
    this.setState({ vistaActual: 1 });
    //alert(this.state.idYoutubeActual)
  }
  fnVerVideo003() {    
    this.setState({ idYoutubeActual: this.state.item03.E_ID_YOUTUBE });
    this.setState({ vistaActual: 1 });
  }
  fnVerVideo004() {    
    this.setState({ idYoutubeActual: this.state.item04.E_ID_YOUTUBE });
    this.setState({ vistaActual: 1 });
  }
  fnVerVideo005() {    
    this.setState({ idYoutubeActual: this.state.item05.E_ID_YOUTUBE });
    this.setState({ vistaActual: 1 });
  }
  fnVerVideo006() {    
    this.setState({ idYoutubeActual: this.state.item06.E_ID_YOUTUBE });
    this.setState({ vistaActual: 1 });
  }
  fnVerVideo007() {    
    this.setState({ idYoutubeActual: this.state.item07.E_ID_YOUTUBE });
    this.setState({ vistaActual: 1 });
  }
  fnVerVideo008() {    
    this.setState({ idYoutubeActual: this.state.item08.E_ID_YOUTUBE });
    this.setState({ vistaActual: 1 });
  }
  fnVerVideo009() {    
    this.setState({ idYoutubeActual: this.state.item09.E_ID_YOUTUBE });
    this.setState({ vistaActual: 1 });
  }
  fnVerVideo010() {    
    this.setState({ idYoutubeActual: this.state.item10.E_ID_YOUTUBE });
    this.setState({ vistaActual: 1 });
  }
  fnCerrarVideo() {
    this.setState({ vistaActual: 0 });
  }

  fnCambio() {
    console.log("Fwd");

    var duracionImagen = 10000;
    this.setState({ contGlobal: this.state.contGlobal + 1 });
    //valida si el contador ya se paso
    if (this.state.contGlobal > this.state.dataLista.length - 1) {
      this.setState({ contGlobal: 0 });
    }
    this.setState({
      urlLink: this.state.dataLista[this.state.contGlobal].T_URL_DETALLE,
    });
    this.setState({
      idYoutubeActual: this.state.dataLista[this.state.contGlobal].E_ID_YOUTUBE,
    });
    this.setState({
      urlArchivoActual:
        this.state.urlRepositorio +
        this.state.dataLista[this.state.contGlobal].T_URL_ARCHIVO,
    });
    duracionImagen =
      this.state.dataLista[this.state.contGlobal].T_DURACION_SEG * 1000;
    clearTimeout(this.state.timeOutFwd);
    this.setState({
      timeOutFwd: setTimeout(
        function () {
          // alert("Cambio");
          this.fnCambio();
          // this.setState({ position: 1 });
          // this.fnCambio();
        }.bind(this),
        duracionImagen
      ),
    });
    /*setTimeout(
      function () {
        // alert("Cambio");
        this.fnCambio();
        // this.setState({ position: 1 });
        // this.fnCambio();
      }.bind(this),
      duracionImagen
    );*/
  }
  fnCambioBack() {
    console.log("Bck");
    var duracionImagen = 10000;
    this.setState({ contGlobal: this.state.contGlobal - 1 });
    //valida si el contador ya se paso
    if (this.state.contGlobal < 0) {
      this.setState({ contGlobal: this.state.dataLista.length - 1 });
    }
    this.setState({
      urlLink: this.state.dataLista[this.state.contGlobal].T_URL_DETALLE,
    });
    this.setState({
      idYoutubeActual: this.state.dataLista[this.state.contGlobal].E_ID_YOUTUBE,
    });
    this.setState({
      urlArchivoActual:
        this.state.urlRepositorio +
        this.state.dataLista[this.state.contGlobal].T_URL_ARCHIVO,
    });
    duracionImagen =
      this.state.dataLista[this.state.contGlobal].T_DURACION_SEG * 1000;
    clearTimeout(this.state.timeOutBck);
    this.setState({
      timeOutBck: setTimeout(
        function () {
          // alert("Cambio");
          this.fnCambioBack();
          // this.setState({ position: 1 });
          // this.fnCambio();
        }.bind(this),
        duracionImagen
      ),
    });
  }
  async fnLista() {
    // alert(this.state.urlLista);
    //obtiene sucursales para variable global
    fetch(this.state.urlLista, {
      headers: { "Cache-Control": "no-cache" },
    })
      .then((response) => response.json())
      .then((json) => {
        //alert(json[0].E_ORDEN)
        //  var resultado=JSON.stringify(json);
        // alert(resultado);
        this.setState({ numeroTotal: json.length });
        this.setState({ dataLista: json });
        this.setState({ vistaCarousel: 1 });
        for (var xx = 0; xx <= this.state.dataLista.length - 1; xx++) {
          var archivoThumb = fondoBase;
          if (this.state.dataLista[xx].T_URL_ARCHIVO.length > 3) {
            archivoThumb =
              this.state.urlRepositorio +
              this.state.dataLista[xx].T_URL_ARCHIVO;
          }
          if (xx == 0) {
            this.setState({
              item01: {
                T_TITULO: this.state.dataLista[xx].T_TITULO,
                T_URL_ARCHIVO: archivoThumb,
                E_ID_YOUTUBE: this.state.dataLista[xx].E_ID_YOUTUBE,
              },
            });
          }
          if (xx == 1) {
            this.setState({
              item02: {
                T_TITULO: this.state.dataLista[xx].T_TITULO,
                T_URL_ARCHIVO: archivoThumb,
                E_ID_YOUTUBE: this.state.dataLista[xx].E_ID_YOUTUBE,
              },
            });
          }
          if (xx == 2) {
            this.setState({
              item03: {
                T_TITULO: this.state.dataLista[xx].T_TITULO,
                T_URL_ARCHIVO: archivoThumb,
                E_ID_YOUTUBE: this.state.dataLista[xx].E_ID_YOUTUBE,
              },
            });
          }
          if (xx == 3) {
            this.setState({
              item04: {
                T_TITULO: this.state.dataLista[xx].T_TITULO,
                T_URL_ARCHIVO: archivoThumb,
                E_ID_YOUTUBE: this.state.dataLista[xx].E_ID_YOUTUBE,
              },
            });
          }
          if (xx == 4) {
            this.setState({
              item05: {
                T_TITULO: this.state.dataLista[xx].T_TITULO,
                T_URL_ARCHIVO: archivoThumb,
                E_ID_YOUTUBE: this.state.dataLista[xx].E_ID_YOUTUBE,
              },
            });
          }
          if (xx == 5) {
            this.setState({
              item06: {
                T_TITULO: this.state.dataLista[xx].T_TITULO,
                T_URL_ARCHIVO: archivoThumb,
                E_ID_YOUTUBE: this.state.dataLista[xx].E_ID_YOUTUBE,
              },
            });
          }
          if (xx == 6) {
            this.setState({
              item07: {
                T_TITULO: this.state.dataLista[xx].T_TITULO,
                T_URL_ARCHIVO: archivoThumb,
                E_ID_YOUTUBE: this.state.dataLista[xx].E_ID_YOUTUBE,
              },
            });
          }
          if (xx == 7) {
            this.setState({
              item08: {
                T_TITULO: this.state.dataLista[xx].T_TITULO,
                T_URL_ARCHIVO: archivoThumb,
                E_ID_YOUTUBE: this.state.dataLista[xx].E_ID_YOUTUBE,
              },
            });
          }
          if (xx == 8) {
            this.setState({
              item09: {
                T_TITULO: this.state.dataLista[xx].T_TITULO,
                T_URL_ARCHIVO: archivoThumb,
                E_ID_YOUTUBE: this.state.dataLista[xx].E_ID_YOUTUBE,
              },
            });
          }
          if (xx == 9) {
            this.setState({
              item10: {
                T_TITULO: this.state.dataLista[xx].T_TITULO,
                T_URL_ARCHIVO: archivoThumb,
                E_ID_YOUTUBE: this.state.dataLista[xx].E_ID_YOUTUBE,
              },
            });
          }
        }
        //alert(this.state.dataLista[3].T_TITULO);
        //   this.setState({
        //     urlArchivoActual: this.state.urlRepositorio + json[0].T_URL_ARCHIVO,
        //   });
        // alert(this.state.urlArchivoActual);
        //  this.fnCambio();

        // alert(JSON.stringify(json));
      })
      .catch((error) => alert(error))
      .finally(() => {
        // alert(JSON.stringify(this.state.dataSucursales));
        // this.setState({ isLoading: false });
      });
  }

  render() {
    const opts = {
      height: "390",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

    let contenidosCarousel = <div></div>;
    if (this.state.vistaCarousel == 1) {
      contenidosCarousel = (
        <div>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            arrows={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-60-px"
            
         

          >
            <div className="item">
              <a href="#" onClick={this.fnVerVideo001} className="tituloVideo">
                <img
                  src={this.state.item01.T_URL_ARCHIVO}
                  className="frameImg"
                />
                <br />
                {this.state.item01.T_TITULO}
              </a>
            </div>
            <div className="item">
              <a href="#" onClick={this.fnVerVideo002} className="tituloVideo">
                <img
                  src={this.state.item02.T_URL_ARCHIVO}
                  className="frameImg"
                />
                <br />
                {this.state.item02.T_TITULO}
              </a>
            </div>
            <div className="item">
              <a href="#" onClick={this.fnVerVideo003} className="tituloVideo">
                <img
                  src={this.state.item03.T_URL_ARCHIVO}
                  className="frameImg"
                />
                <br />
                {this.state.item03.T_TITULO}
              </a>
            </div>
            <div className="item">
              <a href="#" onClick={this.fnVerVideo004} className="tituloVideo">
                <img
                  src={this.state.item04.T_URL_ARCHIVO}
                  className="frameImg"
                />
                <br />
                {this.state.item04.T_TITULO}
              </a>
            </div>
            <div className="item">
              <a href="#" onClick={this.fnVerVideo005} className="tituloVideo">
                <img
                  src={this.state.item05.T_URL_ARCHIVO}
                  className="frameImg"
                />
                <br />
                {this.state.item05.T_TITULO}
              </a>
            </div>
            <div className="item">
              <a href="#" onClick={this.fnVerVideo006} className="tituloVideo">
                <img
                  src={this.state.item06.T_URL_ARCHIVO}
                  className="frameImg"
                />
                <br />
                {this.state.item06.T_TITULO}
              </a>
            </div>
            <div className="item">
              <a href="#" onClick={this.fnVerVideo007} className="tituloVideo">
                <img
                  src={this.state.item07.T_URL_ARCHIVO}
                  className="frameImg"
                />
                <br />
                {this.state.item07.T_TITULO}
              </a>
            </div>
            <div className="item">
              <a href="#" onClick={this.fnVerVideo008} className="tituloVideo">
                <img
                  src={this.state.item08.T_URL_ARCHIVO}
                  className="frameImg"
                />
                <br />
                {this.state.item08.T_TITULO}
              </a>
            </div>
            <div className="item">
              <a href="#" onClick={this.fnVerVideo009} className="tituloVideo">
                <img
                  src={this.state.item09.T_URL_ARCHIVO}
                  className="frameImg"
                />
                <br />
                {this.state.item09.T_TITULO}
              </a>
            </div>
            <div className="item">
              <a href="#" onClick={this.fnVerVideo010} className="tituloVideo">
                <img
                  src={this.state.item10.T_URL_ARCHIVO}
                  className="frameImg"
                />
                <br />
                {this.state.item10.T_TITULO}
              </a>
            </div>
            
           
          </Carousel>
          <br />
          <a href={this.state.urlLinkCanal} target="_blank">
            <img src="https://wassermatic.com.mx/assets/img/iconYoutube.png" height="15" />
             Ver Canal
          </a>
        </div>
      );
    } else {
      contenidosCarousel = <div></div>;
    }

    let contenidosYouTube = <div></div>;
    if (this.state.vistaActual == 1) {
      //"MS3FJ4yJmUQ" id sample
      contenidosYouTube = (
        <div className="panelTube">
          <YouTube
            videoId={this.state.idYoutubeActual}
            opts={opts}
            onReady={this._onReady}
          />
          <button onClick={this.fnCerrarVideo} className="btCerrar">
            Cerrar
          </button>
        </div>
      );
    } else {
      contenidosYouTube = <div></div>;
    }

    return (
      <div className="fondoFramesTube">
        {contenidosYouTube}
        {contenidosCarousel}
        
      </div>
    );
  }
  _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  }
}
export default CompYoutubeGallery;
