
import './App.css';
import CompYoutubeGallery from "./screens/CompYoutubeGallery";
function App() {
  return (
    <div className="App">
      <CompYoutubeGallery />
    </div>
  );
}

export default App;
